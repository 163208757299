// import {SUCCESS_LOGIN} from '../../action/generic';
// import * as generic from '../../action/generic';



const initialState = {
    hideButton:false,
    CompanyName:"",
    screenName:""
  }
  
  function reducer(state={initialState}, action) {
    // console.log('action ',initialState)
    switch(action.type){
        case  "HIDE_BUTTON" : 
        return state= {...state,hideButton:!state.hideButton}
        case "LOGIN":{
        
          return state = {CompanyName:action.payload.companyName, screenName: action.payload.screenName,profile_image_url:action.payload.profile_image_url}
        
        }   
    }
    // For now, don't handle any actions
    // and just return the state given to us.
    return state
  }

  export default reducer;